import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box, Flex, VStack, HStack, Heading, Text, Button, useColorModeValue,
  AspectRatio, Tag, Wrap, WrapItem, Icon, Tooltip, useDisclosure,
  Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  Spinner, Avatar, Badge, SimpleGrid, useMediaQuery
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaEdit, FaYoutube, FaTiktok, FaInstagram, FaClock, FaHSquare, FaPaintBrush, FaMusic, FaClosedCaptioning } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';
import BottomTabBar from '../components/BottomTabBar';

const MotionBox = motion(Box);

const MetadataItem = ({ icon, label, value }) => {
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <HStack spacing={2} alignItems="center" bg={bgColor} p={2} borderRadius="md">
      <Icon as={icon} color="purple.500" boxSize={5} />
      <VStack align="start" spacing={0}>
        <Text fontSize="xs" fontWeight="medium" color={labelColor}>{label}</Text>
        <Text fontSize="sm" fontWeight="bold" color={textColor}>{value}</Text>
      </VStack>
    </HStack>
  );
};

const PlatformButton = ({ icon: IconComponent, label, onClick, isScheduled }) => (
  <Tooltip label={isScheduled ? `Scheduled for ${new Date(isScheduled).toLocaleString()}` : `Schedule for ${label}`}>
    <Button
      leftIcon={<IconComponent />}
      onClick={onClick}
      colorScheme={isScheduled ? "purple" : "gray"}
      size="sm"
      variant={isScheduled ? "solid" : "outline"}
      borderRadius="full"
    >
      {label}
    </Button>
  </Tooltip>
);

function GeneratedVideo() {
  const { videoId } = useParams();
  const location = useLocation();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [videoDetails, setVideoDetails] = useState(location.state?.videoDetails || {});
  const [scenes, setScenes] = useState([]);
  const [script, setScript] = useState('');
  const [category, setCategory] = useState('');
  const [duration, setDuration] = useState(null);
  const [isLoading, setIsLoading] = useState(!location.state?.videoDetails);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue('gray.50', '#121212');
  const cardBgColor = useColorModeValue('white', '#1E1E1E');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');
  const accentColor = useColorModeValue('purple.500', 'purple.300');
  const scriptBgColor = useColorModeValue('gray.50', 'gray.800');
  const scriptTextColor = useColorModeValue('gray.800', 'gray.100');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideoDetails = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`https://api.clipzy.ai/video/all-details/${videoId}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        setVideoDetails(response.data.video);
        setScenes(response.data.scenes);
        setCategory(response.data.script.category || 'N/A');

        // Combine and concatenate captions from scenes with a single space
        const combinedScript = response.data.scenes
          .map(scene => scene.caption)
          .join(' ');
        setScript(combinedScript);

        // Set duration based on the end time of the last scene
        if (response.data.scenes.length > 0) {
          const lastScene = response.data.scenes[response.data.scenes.length - 1];
          setDuration(lastScene.end_time);
        }

      } catch (error) {
        console.error('Error fetching video details:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideoDetails();
  }, [videoId]);

  const handleSchedule = (platform) => {
    setVideoDetails(prevDetails => ({
      ...prevDetails,
      scheduledPlatforms: {
        ...prevDetails.scheduledPlatforms,
        [platform]: new Date().toISOString()
      }
    }));
  };

  const handleEditVideo = () => {
    navigate(`/timeline/${videoId}`);
  };

  return (
    <Flex flexDirection="column" minHeight="100vh" bg={bgColor}>
      <Flex flex={1}>
        {!isMobile && <Sidebar />}
        <Box flex={1} py={8} px={4}>
          <VStack spacing={8} align="stretch" maxW="1200px" mx="auto">
            <MotionBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              bg={cardBgColor}
              borderRadius="xl"
              overflow="hidden"
              boxShadow="xl"
            >
              <Flex direction={{ base: 'column', md: 'row' }} align="start">
                <Box width={{ base: '100%', md: '50%' }} p={6}>
                  <AspectRatio ratio={9 / 16} width="100%" height={{ base: 'auto', md: '100%' }}>
                    {isLoading ? (
                      <Flex justify="center" align="center">
                        <Spinner size="xl" color={accentColor} />
                      </Flex>
                    ) : (
                      <video
                        src={videoDetails.filePath}
                        controls
                        style={{ objectFit: 'cover', borderRadius: '12px', width: '100%', height: '100%' }}
                      />
                    )}
                  </AspectRatio>
                </Box>
                <VStack flex={1} align="stretch" p={6} spacing={6}>
                  <Flex justify="space-between" align="center">
                    <VStack align="start" spacing={2}>
                      <Heading size="lg" color={textColor}>{videoDetails.title}</Heading>
                    </VStack>
                    <Button leftIcon={<FaEdit />} onClick={handleEditVideo} size="sm" colorScheme="purple">
                      Edit Video
                    </Button>
                  </Flex>

                  <Box bg={scriptBgColor} p={4} borderRadius="md" boxShadow="sm">
                    <Heading size="sm" color={textColor} mb={2}>Script</Heading>
                    <Text fontSize="sm" color={scriptTextColor} whiteSpace="pre-wrap">
                      {script || "No script available."}
                    </Text>
                  </Box>

                  <VStack align="stretch" spacing={3}>
                    <HStack justify="space-between">
                      <Avatar name={videoDetails.voiceName} size="sm" bg="purple.500" />
                      <Text fontWeight="bold" fontSize="sm" color={textColor}>Voice: {videoDetails.voiceName || "N/A"}</Text>
                    </HStack>
                    <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
                      <MetadataItem icon={FaClock} label="Duration" value={`${duration || 'N/A'} seconds`} />
                      <MetadataItem icon={FaHSquare} label="Category" value={category} />
                      <MetadataItem icon={FaPaintBrush} label="Art Style" value={videoDetails.artStyle || "N/A"} />
                      <MetadataItem icon={FaMusic} label="Music" value={videoDetails.backgroundMusic || "N/A"} />
                      <MetadataItem icon={FaClosedCaptioning} label="Subtitles" value={videoDetails.subtitleStyle || "N/A"} />
                    </SimpleGrid>
                  </VStack>

                  <VStack align="stretch" spacing={2}>
                    <Heading size="sm" color={textColor}>Schedule Post</Heading>
                    <HStack spacing={2}>
                      <PlatformButton
                        icon={FaYoutube}
                        label="YouTube"
                        onClick={() => handleSchedule('youtube')}
                        isScheduled={videoDetails.scheduledPlatforms?.youtube}
                      />
                      <PlatformButton
                        icon={FaTiktok}
                        label="TikTok"
                        onClick={() => handleSchedule('tiktok')}
                        isScheduled={videoDetails.scheduledPlatforms?.tiktok}
                      />
                      <PlatformButton
                        icon={FaInstagram}
                        label="Instagram"
                        onClick={() => handleSchedule('instagram')}
                        isScheduled={videoDetails.scheduledPlatforms?.instagram}
                      />
                    </HStack>
                  </VStack>

                  <Wrap>
                    {videoDetails.tags && videoDetails.tags.map((tag, index) => (
                      <WrapItem key={index}>
                        <Tag size="sm" colorScheme="purple" borderRadius="full">{tag}</Tag>
                      </WrapItem>
                    ))}
                  </Wrap>

                </VStack>
              </Flex>
            </MotionBox>
          </VStack>
        </Box>
      </Flex>
      {isMobile && <BottomTabBar />}

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent bg={cardBgColor} color={textColor}>
          <DrawerCloseButton />
          <DrawerHeader>Edit Video Details</DrawerHeader>
          <DrawerBody>
            <Text>Edit form coming soon...</Text>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

export default GeneratedVideo;