import React from 'react';
import { VStack, Heading, SimpleGrid, Box, Image, Text, Input,FormControl,FormLabel } from '@chakra-ui/react';

// Array of topics with Unsplash thumbnails
const customBackgroundVideos = [
  { name: "Custom", thumbnail: "https://plus.unsplash.com/premium_photo-1719289799297-f5491a1de014?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
  { name: "Fun Facts", thumbnail: "https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?w=300&h=200&fit=crop" },
  { name: "How To", thumbnail: "https://images.unsplash.com/photo-1676282824041-67dfaa132081?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
  { name: "Motivational", thumbnail: "https://images.unsplash.com/photo-1496181133206-80ce9b88a853?w=300&h=200&fit=crop" },
  { name: "Personal", thumbnail: "https://plus.unsplash.com/premium_photo-1687067885966-d755107af021?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
  { name: "Horror", thumbnail: "https://plus.unsplash.com/premium_photo-1695229112511-1dfeedd2e355?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
  { name: "Life Hack", thumbnail: "https://images.unsplash.com/photo-1543269865-cbf427effbad?w=300&h=200&fit=crop" },
  { name: "Fantasy", thumbnail: "https://plus.unsplash.com/premium_photo-1682308191763-2813d4a2e746?q=80&w=2906&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
  { name: "Business", thumbnail: "https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?w=300&h=200&fit=crop" },
  { name: "Philosophy", thumbnail: "https://plus.unsplash.com/premium_photo-1682308429561-930e3df7ca6a?q=80&w=2795&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" }
];

const TopicCard = ({ topic, isSelected, onClick }) => (
  <Box 
    borderWidth={isSelected ? '2px' : '1px'} 
    borderColor={isSelected ? 'blue.500' : 'gray.200'} 
    borderRadius="lg" 
    overflow="hidden" 
    cursor="pointer"
    onClick={onClick}
  >
    <Image 
      src={topic.thumbnail} 
      alt={topic.name} 
      width="100%" 
      height="100px" 
      objectFit="cover"
    />
    <Box p={2}>
      <Text fontWeight="bold" textAlign="center">{topic.name}</Text>
    </Box>
  </Box>
);

const TopicStep = ({ formData, setFormData }) => {
  const handleTopicSelect = (topic) => {
    setFormData(prev => ({ ...prev, topic: topic.name }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <VStack spacing={6} align="stretch">
      <Heading size="lg">Choose Your Topic</Heading>
      <SimpleGrid columns={[2, 3, 4]} spacing={4}>
        {customBackgroundVideos.map((topic) => (
          <TopicCard
            key={topic.name}
            topic={topic}
            isSelected={formData.topic === topic.name}
            onClick={() => handleTopicSelect(topic)}
          />
        ))}
      </SimpleGrid>
      {formData.topic === "Custom" && (
        <Input
          name="customTopic"
          placeholder="Type your custom topic here"
          value={formData.customTopic || ''}
          onChange={handleInputChange}
        />
      )}
      <FormControl>
        <FormLabel>Keywords (comma-separated)</FormLabel>
        <Input
          name="keywords"
          placeholder="Enter keywords, separated by commas"
          value={formData.keywords}
          onChange={handleInputChange}
        />
      </FormControl>
    </VStack>
  );
};

export default TopicStep;
