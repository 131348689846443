import { FaPencilAlt, FaImage, FaVolumeUp, FaClosedCaptioning, FaRocket } from 'react-icons/fa';

export const captionTemplates = [
    {
        name: "Bold Glow",
        style: {
            fontFamily: "LUCKIEST GUY, cursive",
            fontSize: 51,
            fontWeight: "bold",
            fontStyle: "normal",
            textCase: "Uppercase",
            textColor: "#ffffff",
            highlightColor: "#f8e71c",
            highlightStrokeColor: "#000000",
            highlightBackgroundColor: "#00000050",
            textAlignment: "center",
            textEffect: "Glow",
            backgroundColor: "Solid Color",
            backgroundGradient: ["#000000", "#000000"],
            backgroundBlur: 0,
            wordsPerLine: 5,
            lineHeight: 1.5,
            effectIntensity: 100,
            renderingEffect: "One word at a time",
            textStrokeWidth: 8,
            textStrokeColor: "#f5a623",
            highlightStrokeWidth: 0,
            textWidth: "normal",
            highlightCornerRadius: 4,
            highlightBackgroundEnabled: true,
            highlightLogic: "Middle",
            highlightEnabled: true
        }
    },
    {
        name: "Neon Pulse",
        style: {
            fontFamily: "Orbitron, sans-serif",
            fontSize: 48,
            fontWeight: "bold",
            fontStyle: "normal",
            textCase: "Uppercase",
            textColor: "#00ff00",
            highlightColor: "#ff00ff",
            highlightStrokeColor: "#ffffff",
            highlightBackgroundColor: "#00000080",
            textAlignment: "center",
            textEffect: "Glow",
            backgroundColor: "Gradient",
            backgroundGradient: ["#000033", "#330033"],
            backgroundBlur: 0,
            wordsPerLine: 4,
            lineHeight: 1.3,
            effectIntensity: 150,
            renderingEffect: "One word at a time",
            textStrokeWidth: 2,
            textStrokeColor: "#ffffff",
            highlightStrokeWidth: 1,
            textWidth: "wide",
            highlightCornerRadius: 8,
            highlightBackgroundEnabled: true,
            highlightLogic: "Random",
            highlightEnabled: true
        }
    },
    {
        name: "Minimalist Clean",
        style: {
            "fontFamily": "REBUCKED, fantasy",
            "fontSize": 33,
            "fontWeight": "normal",
            "fontStyle": "normal",
            "textCase": "Uppercase",
            "textColor": "#FFFFFF",
            "highlightColor": "#ffffff",
            "highlightStrokeColor": "#000000",
            "highlightBackgroundColor": "#d0021b",
            "textAlignment": "center",
            "textEffect": "None",
            "backgroundColor": "Solid Color",
            "backgroundGradient": [
                "#000000",
                "#FFFFFF"
            ],
            "backgroundBlur": 0,
            "wordsPerLine": 3,
            "lineHeight": 1.2,
            "effectIntensity": 50,
            "renderingEffect": "One word at a time",
            "textStrokeWidth": 4,
            "textStrokeColor": "#000000",
            "highlightStrokeWidth": 1.5,
            "textWidth": "normal",
            "highlightCornerRadius": 4,
            "highlightBackgroundEnabled": true,
            "highlightLogic": "Middle",
            "highlightEnabled": true
        }
    },
    {
        name: "Retro Vibes",
        style: {
            "fontFamily": "REBUCKED, fantasy",
            "fontSize": 38,
            "fontWeight": "bold",
            "fontStyle": "normal",
            "textCase": "Uppercase",
            "textColor": "#FFFFFF",
            "highlightColor": "#d0021b",
            "highlightStrokeColor": "#000000",
            "highlightBackgroundColor": "transparent",
            "textAlignment": "center",
            "textEffect": "None",
            "backgroundColor": "transparent",
            "backgroundGradient": [
                "#000000",
                "#FFFFFF"
            ],
            "backgroundBlur": 0,
            "wordsPerLine": 5,
            "lineHeight": 1.5,
            "effectIntensity": 50,
            "renderingEffect": "All at once",
            "textStrokeWidth": 8.5,
            "textStrokeColor": "#000000",
            "highlightStrokeWidth": 8.5,
            "textWidth": "normal",
            "highlightCornerRadius": 4,
            "highlightBackgroundEnabled": false,
            "highlightLogic": "Middle",
            "highlightEnabled": true
        }
    },
    {
        name: "Elegant Serif",
        style: {
            "fontFamily": "DEUTSCH, fantasy",
            "fontSize": 33,
            "fontWeight": "normal",
            "fontStyle": "normal",
            "textCase": "Uppercase",
            "textColor": "#FFFFFF",
            "highlightColor": "#FFFF00",
            "highlightStrokeColor": "#000000",
            "highlightBackgroundColor": "#00000050",
            "textAlignment": "center",
            "textEffect": "None",
            "backgroundColor": "Solid Color",
            "backgroundGradient": [
                "#000000",
                "#FFFFFF"
            ],
            "backgroundBlur": 0,
            "wordsPerLine": 5,
            "lineHeight": 1.5,
            "effectIntensity": 50,
            "renderingEffect": "One word at a time",
            "textStrokeWidth": 0,
            "textStrokeColor": "#000000",
            "highlightStrokeWidth": 0,
            "textWidth": "normal",
            "highlightCornerRadius": 4,
            "highlightBackgroundEnabled": true,
            "highlightLogic": "Middle",
            "highlightEnabled": true
        }
    },
    {
        name: "Bold Marker",
        style: {
            fontFamily: "PERMANENT MARKER, cursive",
            fontSize: 42,
            fontWeight: "normal",
            fontStyle: "normal",
            textCase: "Uppercase",
            textColor: "#FFFFFF",
            highlightColor: "#ffffff",
            highlightStrokeColor: "#000000",
            highlightBackgroundColor: "#4a90e2",
            textAlignment: "center",
            textEffect: "None",
            backgroundColor: "transparent",
            backgroundGradient: ["#000000", "#FFFFFF"],
            backgroundBlur: 0,
            wordsPerLine: 2,
            lineHeight: 1.5,
            effectIntensity: 50,
            renderingEffect: "One word at a time",
            textStrokeWidth: 8.5,
            textStrokeColor: "#000000",
            highlightStrokeWidth: 0,
            textWidth: "narrow",
            highlightCornerRadius: 19,
            highlightBackgroundEnabled: true,
            highlightLogic: "Middle",
            highlightEnabled: true
        }
    },
    {
        name: "New Yellow Caption",
        style: {
            "fontFamily": "MONTSERRAT, sans-serif",
            "fontSize": 24,
            "fontWeight": "normal",
            "fontStyle": "normal",
            "textCase": "Sentence Case",
            "textColor": "#FFFFFF",
            "highlightColor": "#FFFF00",
            "highlightStrokeColor": "#000000",
            "highlightBackgroundColor": "#00000050",
            "textAlignment": "center",
            "textEffect": "None",
            "backgroundColor": "transparent",
            "backgroundGradient": [
                "#000000",
                "#FFFFFF"
            ],
            "backgroundBlur": 0,
            "emphasisTechnique": "Bold",
            "wordsPerLine": 5,
            "lineHeight": 1.5,
            "effectIntensity": 50,
            "textStrokeWidth": 0,
            "textStrokeColor": "#000000",
            "highlightStrokeWidth": 0,
            "highlightCornerRadius": 4,
            "renderingEffect": "One word at a time"
        }
    },
    {
        name: "New Blue Caption",
        style: {
            "fontFamily": "MONTSERRAT, sans-serif",
            "fontSize": 24,
            "fontWeight": "normal",
            "fontStyle": "normal",
            "textCase": "Sentence Case",
            "textColor": "#FFFFFF",
            "highlightColor": "#ADD8E6",
            "highlightStrokeColor": "#000000",
            "highlightBackgroundColor": "#00000050",
            "textAlignment": "center",
            "textEffect": "None",
            "backgroundColor": "transparent",
            "backgroundGradient": [
                "#000000",
                "#FFFFFF"
            ],
            "backgroundBlur": 0,
            "emphasisTechnique": "Bold",
            "wordsPerLine": 5,
            "lineHeight": 1.5,
            "effectIntensity": 50,
            "textStrokeWidth": 0,
            "textStrokeColor": "#000000",
            "highlightStrokeWidth": 0,
            "highlightCornerRadius": 4,
            "renderingEffect": "One word at a time"
        }
    }
];

export const loadingStages = [
    { icon: FaPencilAlt, text: "Crafting your story", color: "blue.400" },
    { icon: FaImage, text: "Painting your world", color: "green.400" },
    { icon: FaVolumeUp, text: "Adding the perfect voice", color: "purple.400" },
    { icon: FaClosedCaptioning, text: "Captioning your masterpiece", color: "orange.400" },
    { icon: FaRocket, text: "Launching your creation", color: "red.400" }
];

export const funFacts = [
    "Did you know? AI Reels can generate videos 10x faster than traditional methods!",
    "Fun fact: Our AI can understand and create content in over 50 languages.",
    "Wow! AI Reels has created over 1 million unique videos for creators worldwide.",
    "Amazing: Our AI can analyze trends and predict viral content with 85% accuracy.",
    "Interesting: AI Reels uses over 100 million data points to create each video.",
    "Cool fact: Our AI can generate custom music that perfectly fits your video theme.",
    "Did you know? AI Reels' facial recognition can track emotions in generated characters.",
    "Incredible: Our AI can simulate realistic physics in animated scenes.",
    "Mind-blowing: AI Reels can create an infinite number of unique video styles.",
    "Awesome: Our AI learns from each video creation, constantly improving its skills.",
    "YouTube Fun Fact: The first video ever uploaded to YouTube was only 18 seconds long!",
    "Did you know? Over 500 hours of video are uploaded to YouTube every minute.",
    "Interesting: The most viewed YouTube video has over 10 billion views!",
    "YouTube Trivia: The platform was originally designed as a video dating site.",
    "Wow! YouTube is the second most visited website in the world, after Google."
];