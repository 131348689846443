import React, { useCallback } from 'react';
import { Box, Flex, VStack, Heading, Button, useColorModeValue, Text, useMediaQuery } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiChevronLeft, FiChevronRight, FiCheck } from 'react-icons/fi';
import { useVideoCreation } from '../hooks/useVideoCreation';
import TopicStep from './Videocreation/steps/TopicStep';
import BackgroundStep from './Videocreation/steps/BackgroundStep';
import VisualStyleStep from './Videocreation/steps/VisualStyleStep';
import VoiceStep from './Videocreation/steps/VoiceStep';
import CaptionStep from './Videocreation/steps/CaptionStep';
import LoadingScreen from './Videocreation/steps/LoadingScreen';
import CompletionScreen from './Videocreation/steps/CompletionScreen';
import ErrorMessage from './Videocreation/steps/ErrorMessage';
import StepperComponent from './Videocreation/steps/StepperComponent';
import Sidebar from './Sidebar';
import BottomTabBar from './BottomTabBar';

const MotionBox = motion(Box);

function VideoCreationFlow({ onVideoGenerated }) {
    const {
        activeStep,
        selectedBackground,
        selectedVoice,
        selectedCaptionTemplate,
        isGenerating,
        generationComplete,
        error,
        formData,
        setFormData,
        handleNext,
        handleBack,
        handleSubmit,
        getSteps,
        setSelectedBackground,
        setSelectedVoice,
        setSelectedCaptionTemplate,
    } = useVideoCreation(onVideoGenerated);

    const steps = getSteps();

    const bgColor = useColorModeValue('gray.50', '#121212');
    const textColor = useColorModeValue('gray.800', 'white');

    const [isMobile] = useMediaQuery("(max-width: 768px)");

    const handleNextWithLog = () => {
        console.log('TopicStep data:', formData);
        console.log('BackgroundStep data:', selectedBackground);

        if (activeStep === 0) {
            // Topic step
            console.log('TopicStep data:', formData);
        } else if (activeStep === 1) {
            // Background step
            console.log('BackgroundStep data:', selectedBackground);
        } else if (activeStep === 2) {
            if (selectedBackground === 'ai') {
                console.log('VisualStyleStep data:', formData.imageStyle);
            } else {
                console.log('VoiceStep data:', selectedVoice);
            }
        } else if (activeStep === 3) {
            if (selectedBackground === 'ai') {
                console.log('VoiceStep data:', selectedVoice);
            } else {
                console.log('CaptionStep data:', selectedCaptionTemplate);
            }
        } else if (activeStep === 4) {
            console.log('CaptionStep data:', selectedCaptionTemplate);
        }

        handleNext();
    };

    const handleSubmitWithLog = () => {
        console.log('TopicStep data:', formData);
        console.log('BackgroundStep data:', selectedBackground);
        console.log('VoiceStep data:', selectedVoice);
        console.log('CaptionStep data:', selectedCaptionTemplate);
        handleSubmit();
    };

    const renderStep = useCallback(() => {
        switch (activeStep) {
            case 0:
                return <TopicStep formData={formData} setFormData={setFormData} />;
            case 1:
                return <BackgroundStep selectedBackground={selectedBackground} setSelectedBackground={setSelectedBackground} />;
            case 2:
                if (selectedBackground === 'ai') {
                    return <VisualStyleStep formData={formData} setFormData={setFormData} />;
                } else {
                    return <VoiceStep selectedVoice={selectedVoice} setSelectedVoice={setSelectedVoice} />;
                }
            case 3:
                if (selectedBackground === 'ai') {
                    return <VoiceStep selectedVoice={selectedVoice} setSelectedVoice={setSelectedVoice} />;
                } else {
                    return <CaptionStep selectedCaptionTemplate={selectedCaptionTemplate} setSelectedCaptionTemplate={setSelectedCaptionTemplate} />;
                }
            case 4:
                return <CaptionStep selectedCaptionTemplate={selectedCaptionTemplate} setSelectedCaptionTemplate={setSelectedCaptionTemplate} />;
            default:
                return null;
        }
    }, [activeStep, selectedBackground, formData, setFormData, selectedVoice, setSelectedVoice, selectedCaptionTemplate, setSelectedCaptionTemplate]);

    if (isGenerating) return <LoadingScreen />;
    if (generationComplete) return <CompletionScreen />;

    return (
        <Flex flexDirection="column" height="100vh" pb={{ base: 24, md: 8 }}>
            <Flex flex={1} overflow="hidden">
                {!isMobile && <Sidebar />}
                <Box flex={1} display="flex" flexDirection="column" bg={bgColor} overflowY="auto">
                    <Box p={4}>
                        <VStack spacing={8} align="stretch" maxW={isMobile ? "100%" : "1200px"} mx="auto">
                            <Heading size={isMobile ? "lg" : "xl"} textAlign="center">Create Your Video</Heading>
                            <StepperComponent steps={steps} activeStep={activeStep} />
                            {error && <ErrorMessage message={error} />}
                            <AnimatePresence mode="wait">
                                <MotionBox
                                    key={activeStep}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    {renderStep()}
                                    <Box mt={8} display="flex" justifyContent="space-between" flexDirection={isMobile ? "column" : "row"}>
                                        {activeStep > 0 && (
                                            <Button onClick={handleBack} leftIcon={<FiChevronLeft />} mb={isMobile ? 4 : 0} w={isMobile ? "100%" : "auto"}>
                                                Back
                                            </Button>
                                        )}
                                        {activeStep < steps.length - 1 ? (
                                            <Button onClick={handleNextWithLog} rightIcon={<FiChevronRight />} w={isMobile ? "100%" : "auto"}>
                                                Next
                                            </Button>
                                        ) : (
                                            <Button onClick={handleSubmitWithLog} rightIcon={<FiCheck />} colorScheme="green" w={isMobile ? "100%" : "auto"}>
                                                Generate Video
                                            </Button>
                                        )}
                                    </Box>
                                </MotionBox>
                            </AnimatePresence>
                        </VStack>
                    </Box>
                </Box>
                 {isMobile && <BottomTabBar />}

             </Flex>
        </Flex>
    );
}

export default VideoCreationFlow;