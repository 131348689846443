import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  RepeatIcon,
  RepeatClockIcon,
  SunIcon,
  MoonIcon,
  SettingsIcon,
  PlusSquareIcon,
  CloseIcon,
  AddIcon,
  ArrowBackIcon,
  EditIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  Text,
  Image,
  Tooltip,
  useColorMode,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  VStack,
  useToast,
  Heading,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';
import WaveSurfer from 'wavesurfer.js';
import initialClips from './data/initialClips';
import wordTimings from './data/wordTimings';
import RemotionPlayer from './RemotionPlayer';
import Sidebar from '../pages/Sidebar';
import EditPanel from './panels/EditPanel';
import VoicePanel from './panels/VoicePanel';
import OverlayPanel from './panels/OverlayPanel';
import MusicPanel from './panels/MusicPanel';

import { EditClipModal } from './EditClipModal';
import { AddClipModal } from './AddClipModal';

function Timeline() {
  const totalDuration = useMemo(() => Math.ceil(wordTimings[wordTimings.length - 1].end), []);
  const [clips, setClips] = useState(initialClips);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRepeat, setIsRepeat] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const timelineRef = useRef(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const [currentClip, setCurrentClip] = useState(null);
  const [selectedSidebarItem, setSelectedSidebarItem] = useState('Music');
  const waveformRef = useRef(null);
  const waveSurfer = useRef(null);
  const [availableSpace, setAvailableSpace] = useState(0);
  const [isAddClipModalOpen, setIsAddClipModalOpen] = useState(false);
  const [newClipStartTime, setNewClipStartTime] = useState(0);

  const [title, setTitle] = useState("My Timeline");
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [selectedClip, setSelectedClip] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const timelineContainerRef = useRef(null);

  const handleTitleClick = () => {
    setIsEditingTitle(true);
  };
  
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  
  const handleTitleBlur = () => {
    setIsEditingTitle(false);
  };

  useEffect(() => {
    if (waveformRef.current) {
      waveSurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'lightgrey',  // Changed from 'gray' to 'lightgrey'
        progressColor: 'black',  // Changed from 'blue' to 'black'
        cursorColor: 'transparent',
        height: 60,
        barWidth: 2,
        responsive: true,
        interact: false,
      });

      waveSurfer.current.load('/audio.mp3');

      waveSurfer.current.on('ready', () => {
        waveSurfer.current.setVolume(0.5);
      });

      return () => {
        if (waveSurfer.current) {
          waveSurfer.current.destroy();
        }
      };
    }
  }, []);

  useEffect(() => {
    if (waveSurfer.current) {
      waveSurfer.current.seekTo(currentTime / totalDuration);
    }
  }, [currentTime, totalDuration]);

  const handleWaveformClick = (e) => {
    const rect = waveformRef.current.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const newTime = (clickPosition / rect.width) * totalDuration;
    setCurrentTime(newTime);
    if (waveSurfer.current) {
      waveSurfer.current.seekTo(newTime / totalDuration);
    }
  };

  const handleMouseDown = (index) => {
    setIsDragging(true);
    setSelectedIndex(index);
  };

  const handleMouseMove = (e) => {
    if (!isDragging || selectedIndex === null) return;
    const timelineWidth = timelineRef.current.offsetWidth;
    const newWidth = (e.clientX - timelineRef.current.getBoundingClientRect().left) / timelineWidth * totalDuration - clips[selectedIndex].startTime;

    setClips(clips.map((clip, i) => {
      if (i === selectedIndex) {
        const minWidth = 1;
        const maxWidth = i === clips.length - 1 ? totalDuration - clip.startTime : clips[i + 1].startTime - clip.startTime;
        return { ...clip, duration: Math.max(minWidth, Math.min(maxWidth, newWidth)) };
      }
      return clip;
    }));
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentTime((prevTime) => {
          const nextTime = prevTime + 0.1 * playbackSpeed;
          if (nextTime > totalDuration) {
            return isRepeat ? 0 : totalDuration;
          }
          return nextTime;
        });
      }, 100);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      if (interval) clearInterval(interval);
    };
  }, [isDragging, selectedIndex, isPlaying, totalDuration, isRepeat, playbackSpeed]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      waveSurfer.current.pause();
    } else {
      waveSurfer.current.play();
    }
  };

  const skipBackward = () => {
    setCurrentTime((prevTime) => Math.max(0, prevTime - 5));
    waveSurfer.current.seekTo((currentTime - 5) / waveSurfer.current.getDuration());
  };

  const skipForward = () => {
    setCurrentTime((prevTime) => Math.min(totalDuration, prevTime + 5));
    waveSurfer.current.seekTo((currentTime + 5) / waveSurfer.current.getDuration());
  };

  const toggleRepeat = () => setIsRepeat(!isRepeat);

  const handleSpeedChange = (speed) => setPlaybackSpeed(speed);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const getCurrentWords = () => {
    return wordTimings
      .filter(wt => wt.start <= currentTime && wt.end > currentTime)
      .map(wt => wt.word)
      .join(' ');
  };

  useEffect(() => {
    const newCurrentClip = clips.find(clip =>
      currentTime >= clip.startTime && currentTime < (clip.startTime + clip.duration)
    );
    if (newCurrentClip !== currentClip) {
      setCurrentClip(newCurrentClip);
    }
  }, [clips, currentTime, isDragging, isPlaying, totalDuration, isRepeat, playbackSpeed]);

  const handleSidebarItemClick = (item) => {
    setSelectedSidebarItem(item === selectedSidebarItem ? null : item);
  };

  const closePanel = () => {
    setSelectedSidebarItem(null);
  };

  const bg = useColorModeValue("white", "gray.900");
  const textColor = useColorModeValue("gray.800", "gray.100");

  const handleTimelineClick = (e) => {
    const rect = timelineContainerRef.current.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const newTime = (clickPosition / rect.width) * totalDuration;
    setCurrentTime(newTime);
    if (waveSurfer.current) {
      waveSurfer.current.seekTo(newTime / totalDuration);
    }
  };

  const handleImageClick = (index) => {
    const clickedClip = clips[index];
    setCurrentTime(clickedClip.startTime);
    setSelectedIndex(index);
    setSelectedClip(clickedClip);

    if (waveSurfer.current) {
      waveSurfer.current.seekTo(clickedClip.startTime / totalDuration);
    }

    if (isPlaying) {
      setIsPlaying(true);
    }
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
  };

  const handleEditClick = (clip) => {
    setSelectedClip(clip);
    setIsPreviewOpen(true);
  };

  const handleUpdateClip = (updatedClip) => {
    if (updatedClip === null) {
      // Handle delete
      setClips(clips.filter(c => c.id !== selectedClip.id));
    } else {
      // Handle update
      setClips(clips.map(c => c.id === selectedClip.id ? updatedClip : c));
    }
  };

  //
  const handleAddClipClick = (startTime, duration) => {
    setNewClipStartTime(startTime);
    setAvailableSpace(duration);
    setIsAddClipModalOpen(true);
  };

  const handleAddClip = (newClipData) => {
    const newClip = {
      id: Date.now(),
      startTime: newClipStartTime,
      duration: availableSpace,
      ...newClipData
    };
    setClips([...clips, newClip].sort((a, b) => a.startTime - b.startTime));
    setIsAddClipModalOpen(false);
  };

  // Function to generate timeline segments
  const generateTimelineSegments = () => {
    let segments = [];
    let lastEndTime = 0;

    clips.forEach((clip) => {
      // Add empty space before clip if there's a gap
      if (clip.startTime > lastEndTime) {
        segments.push({
          type: 'empty',
          startTime: lastEndTime,
          duration: clip.startTime - lastEndTime
        });
      }

      // Add the clip
      segments.push({
        type: 'clip',
        ...clip
      });

      lastEndTime = clip.startTime + clip.duration;
    });

    // Add empty space at the end if needed
    if (lastEndTime < totalDuration) {
      segments.push({
        type: 'empty',
        startTime: lastEndTime,
        duration: totalDuration - lastEndTime
      });
    }

    return segments;
  };

  const timelineSegments = useMemo(generateTimelineSegments, [clips, totalDuration]);

  const handleAutoFill = () => {
    if (selectedIndex === null) return;

    const updatedClips = [...clips];
    const currentClip = updatedClips[selectedIndex];
    const nextClip = updatedClips[selectedIndex + 1];

    if (nextClip) {
      const availableSpace = nextClip.startTime - currentClip.startTime;
      currentClip.duration = availableSpace;
    } else {
      currentClip.duration = totalDuration - currentClip.startTime;
    }

    setClips(updatedClips);
  };

  //
  return (
    <Box height="100vh" overflow="hidden" display="flex" flexDirection="column" bg={bg} color={textColor}>
      <Box style={{paddingTop:'0px', paddingBottom:'0px'}} p={4} borderBottom="1px" borderColor="gray.200">
        <Flex alignItems="center">
          <Link to="/dashboard/content-studio">
            <IconButton
              icon={<ArrowBackIcon />}
              aria-label="Back to Home"
              size="md"
              variant="ghost"
              mr={4}
            />
          </Link>
          {isEditingTitle ? (
            <Input
              value={title}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              fontSize="2xl"
              fontWeight="bold"
              variant="flushed"
              autoFocus
            />
          ) : (
            <Heading
              as="h1"
              size="xl"
              onClick={handleTitleClick}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
            >
              {title}
            </Heading>
          )}
        </Flex>
      </Box>

      {/* Main Content Area */}
      <Flex flex="1.8" overflow="hidden">
         
          <Box > {/* Set flexDirection to column */}
          
            <Sidebar onItemClick={handleSidebarItemClick} selectedItem={selectedSidebarItem} />
          </Box>
          <Box
            width="50%" // Increased width for more space
            display="flex"
            flexDirection="column"
            bgcolor="#f5f5f5" // Light background color for contrast
            borderRadius="8px" // Rounded corners
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)" // Soft shadow for depth
            padding="16px" // Padding for spacing inside the box
          >
            {selectedSidebarItem && (
              <Box
                flex="1"
                position="relative"
                padding="16px" // Space around the panels
                border="1px solid #e0e0e0" // Subtle border around the panel
                borderRadius="8px" // Rounded corners for the panel
                bgcolor="white" // White background for the panel
              >
                {selectedSidebarItem === 'Voice' ? (
                  <VoicePanel />
                ) : selectedSidebarItem === 'Overlay' ? (
                  <OverlayPanel />
                ) : selectedSidebarItem === 'Music' ? (
                  <Box
                  // display="grid" 
                  // gridTemplateColumns="repeat(2, 1fr)" // 2 columns layout for music
                  // gridGap="16px" // Space between items
                  >
                    {/* Music items should be rendered here in a grid */}
                    <MusicPanel />
                  </Box>
                ) : (
                  <EditPanel item={selectedSidebarItem} />
                )}
                <IconButton
                  icon={<CloseIcon />}
                  size="sm"
                  position="absolute"
                  top="10px" // More space from the top
                  right="10px" // More space from the right
                  onClick={closePanel}
                  aria-label="Close panel"
                  sx={{
                    backgroundColor: '#f0f0f0', // Soft background color for the button
                    borderRadius: '50%', // Round button
                    '&:hover': {
                      backgroundColor: '#d0d0d0' // Darker shade on hover
                    }
                  }}
                />
              </Box>
            )}
          </Box>

          <Box width="70%"> 
            <RemotionPlayer
              currentClip={currentClip}
              currentTime={currentTime}
              wordTimings={wordTimings}
            />
          </Box>
        </Flex>
        <hr />
        <Flex  overflow="hidden" flex="1" direction="column" justifyContent="flex-end" pb={0}>
          <Box>
            {/* Timeline controls */}
            <Flex alignItems="center" mb={2}>
              <Flex flex={1}>
                <Tooltip label="Skip backward">
                  <IconButton
                    icon={<ChevronLeftIcon />}
                    aria-label="Skip backward"
                    size="sm"
                    variant="ghost"
                    mr={1}
                    onClick={skipBackward}
                  />
                </Tooltip>
                <Tooltip label={isPlaying ? "Pause" : "Play"}>
                  <IconButton
                    icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />} // Updated to use antd icons
                    aria-label={isPlaying ? "Pause" : "Play"}
                    onClick={togglePlay}
                    size="sm"
                    variant="ghost"
                    mr={1}
                  />
                </Tooltip>

                <Tooltip label="Skip forward">
                  <IconButton
                    icon={<ChevronRightIcon />}
                    aria-label="Skip forward"
                    size="sm"
                    variant="ghost"
                    mr={1}
                    onClick={skipForward}
                  />
                </Tooltip>
                <Tooltip label="Repeat">
                  <IconButton
                    icon={isRepeat ? <RepeatClockIcon /> : <RepeatIcon />}
                    aria-label="Repeat"
                    size="sm"
                    variant="ghost"
                    mr={1}
                    onClick={toggleRepeat}
                  />
                </Tooltip>
                <Menu>
                  <Tooltip label="Playback speed">
                    <MenuButton as={IconButton} icon={<SettingsIcon />} size="sm" variant="ghost" mr={1} />
                  </Tooltip>
                  <MenuList>
                    {[0.5, 1, 1.5, 2].map((speed) => (
                      <MenuItem key={speed} onClick={() => handleSpeedChange(speed)}>
                        {speed}x
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <Text fontSize="sm" fontWeight="medium" mr={2}>
                {Math.floor(currentTime / 60)}:{(currentTime % 60).toFixed(0).padStart(2, '0')} /
                {Math.floor(totalDuration / 60)}:{(totalDuration % 60).toFixed(0).padStart(2, '0')}
              </Text>
              <Flex>
                <Tooltip label="Toggle theme">
                  <IconButton
                    icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                    aria-label="Toggle theme"
                    size="sm"
                    variant="ghost"
                    mr={1}
                    onClick={toggleColorMode}
                  />
                </Tooltip>
                <Tooltip label="Auto-fill">
                  <IconButton
                    icon={<PlusSquareIcon />}
                    aria-label="Auto-fill"
                    size="sm"
                    variant="ghost"
                    mr={1}
                    onClick={handleAutoFill}
                  />
                </Tooltip>
                <Tooltip label="Settings">
                  <IconButton
                    icon={<SettingsIcon />}
                    aria-label="Settings"
                    size="sm"
                    variant="ghost"
                    mr={1}
                  // Add settings functionality here
                  />
                </Tooltip>
                <Tooltip label="Fullscreen">
                  <IconButton
                    icon={<PlusSquareIcon />}
                    aria-label="Fullscreen"
                    size="sm"
                    variant="ghost"
                    onClick={toggleFullscreen}
                  />
                </Tooltip>
              </Flex>
            </Flex>

            {/* Current words display */}
            <Box textAlign="center" mb={2}>
              <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>Current Words: {getCurrentWords()}</Text>
            </Box>

            {/* Timeline */}
            <Box overflow="hidden" position="relative" height="30px" mb={2}>
              {/* Horizontal line */}
              <Box
                position="absolute"
                left="0"
                right="0"
                bottom="0"
                height="2px"
                bg="gray.300"
              />

              {/* Vertical lines and labels */}
              {Array.from({ length: totalDuration + 1 }).map((_, i) => (
                <React.Fragment key={i}>
                  <Box
                    position="absolute"
                    left={`${(i / totalDuration) * 100}%`}
                    bottom="0"
                    height={i % 5 === 0 ? "12px" : "8px"}
                    width="1px"
                    bg="gray.300"
                  />
                  {i % 5 === 0 && (
                    <Text
                      position="absolute"
                      left={`${(i / totalDuration) * 100}%`}
                      bottom="14px"
                      fontSize="xs"
                      color="gray.600"
                      transform="translateX(-50%)"
                    >
                      {i}
                    </Text>
                  )}
                </React.Fragment>
              ))}
            </Box>

            <Box
              ref={timelineContainerRef}
              position="relative"

            >
              <Box ref={waveformRef} mb={2} onClick={handleWaveformClick} cursor="pointer" />
              <Flex overflow="hidden" ref={timelineRef} position="relative" height="100px">
                {timelineSegments.map((segment, index) => {
                  const segmentWidth = (segment.duration / totalDuration) * 100;
                  return (
                    <Box
                      key={segment.type === 'clip' ? segment.id : `empty-${index}`}
                      position="absolute"
                      left={`${(segment.startTime / totalDuration) * 100}%`}
                      width={`${segmentWidth}%`}
                      height="100%"
                      p={1}
                    >
                      {segment.type === 'clip' ? (
                        <>
                          <Image
                            src={segment.src}
                            alt={`Timeline image ${index + 1}`}
                            height="100%"
                            width="100%"
                            objectFit="cover"
                            borderRadius="md"
                            border={selectedIndex === index ? "2px solid #3182CE" : "2px solid #E2E8F0"}
                            onClick={() => handleImageClick(index)}
                            cursor="pointer"
                          />
                          {selectedIndex === index && (
                            <IconButton
                              icon={<EditIcon />}
                              aria-label="Edit clip"
                              size="sm"
                              position="absolute"
                              top="5px"
                              right="5px"
                              onClick={() => handleEditClick(segment)}
                            />
                          )}
                          <Box
                            position="absolute"
                            top="0"
                            right="0"
                            bottom="0"
                            width="10px"
                            cursor="col-resize"
                            onMouseDown={() => handleMouseDown(index)}
                          />
                        </>
                      ) : (
                        <Tooltip label="Add clip">
                          <IconButton
                            icon={<AddIcon />}
                            aria-label="Add clip"
                            size="sm"
                            width="100%"
                            height="100%"
                            onClick={() => handleAddClipClick(segment.startTime, segment.duration)}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  );
                })}
              </Flex>
              <Box
                position="absolute"
                left={`${(currentTime / totalDuration) * 100}%`}
                top="0"
                bottom="0"
                width="2px"
                bg="red.500"
                zIndex="1"
                transition="left 0.1s linear"
              />
            </Box>

          </Box>
        </Flex>
        <EditClipModal
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          selectedClip={selectedClip}
          onUpdateClip={handleUpdateClip}
        />
        <AddClipModal
          isOpen={isAddClipModalOpen}
          onClose={() => setIsAddClipModalOpen(false)}
          onAddClip={handleAddClip}
        />
      </Box>
    );
}

export default Timeline;