import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../App';

export const useLogout = () => {
  const navigate = useNavigate();
  const { setUserInfo } = useContext(UserContext);

  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    // Clear the user info in context
    setUserInfo(null);
    // Navigate to the signin page
    navigate('/signin');
  };

  return handleLogout;
};