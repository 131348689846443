import React, { useCallback, useState, useRef } from 'react';
import { Flex, Box, Button } from '@chakra-ui/react';
import { Player } from '@remotion/player';
import { WouldYouRatherComposition } from './WouldYouRatherComposition';
import { FiDownload } from 'react-icons/fi';

function WouldYouRatherPreview({
  questions,
  delay,
  answerDuration,
  captionSize,
  captionColor,
  captionStrokeWidth,
  captionStrokeColor,
  captionFont,
  aspectRatio,
  backgroundImage,
  backgroundAnimation,
  logoImage,
}) {
  const [isCapturing, setIsCapturing] = useState(false);
  const playerRef = useRef(null);

  const durationInFrames = (60 + 60 + (delay * 30) + 30) * questions.length;
  const fps = 30;

  const renderComposition = useCallback(
    () => (
      <WouldYouRatherComposition
        questions={questions}
        delay={delay}
        answerDuration={answerDuration}
        captionSize={captionSize}
        captionColor={captionColor}
        captionStrokeWidth={captionStrokeWidth}
        captionStrokeColor={captionStrokeColor}
        captionFont={captionFont}
        aspectRatio={aspectRatio}
        backgroundImage={backgroundImage}
        backgroundAnimation={backgroundAnimation}
        logoImage={logoImage}
      />
    ),
    [
      questions,
      delay,
      answerDuration,
      captionSize,
      captionColor,
      captionStrokeWidth,
      captionStrokeColor,
      captionFont,
      aspectRatio,
      backgroundImage,
      backgroundAnimation,
      logoImage,
    ]
  );

  const handleCaptureVideo = async () => {
    setIsCapturing(true);
    const player = playerRef.current;

    if (!player) {
      console.error('Player ref is not available');
      setIsCapturing(false);
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = player.props.compositionWidth;
    canvas.height = player.props.compositionHeight;

    const stream = canvas.captureStream(fps);
    const mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
    const chunks = [];

    mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
    mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, { type: 'video/webm' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'would-you-rather-video.webm';
      a.click();
      URL.revokeObjectURL(url);
      setIsCapturing(false);
    };

    mediaRecorder.start();

    for (let frame = 0; frame < durationInFrames; frame++) {
      await player.seekTo(frame);
      const frameData = await player.getCurrentFrame();
      
      // Create an image from the frame data
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
      };
      img.src = frameData;

      // Wait for the next frame
      await new Promise(resolve => setTimeout(resolve, 1000 / fps));
    }

    mediaRecorder.stop();
  };

  return (
    <Flex w="50%" bg="gray.900" p={4} direction="column" align="center" justify="center">
      <Box 
        w="full" 
        h="full"
        maxW={aspectRatio === '16:9' ? '100%' : '56.25vw'}
        maxH={aspectRatio === '16:9' ? '56.25vw' : '100%'}
      >
        <Player
          ref={playerRef}
          component={renderComposition}
          durationInFrames={durationInFrames}
          compositionWidth={aspectRatio === '16:9' ? 1920 : 1080}
          compositionHeight={aspectRatio === '16:9' ? 1080 : 1920}
          fps={fps}
          style={{
            width: '100%',
            height: '100%',
          }}
          controls
        />
      </Box>
      <Button
        leftIcon={<FiDownload />}
        colorScheme="purple"
        mt={4}
        onClick={handleCaptureVideo}
        isLoading={isCapturing}
        loadingText="Capturing video..."
      >
        Capture Video
      </Button>
    </Flex>
  );
}

export default WouldYouRatherPreview;
