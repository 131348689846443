import React from 'react';
import { VStack, Heading, SimpleGrid, Button, Flex, Box } from '@chakra-ui/react';
import VoiceCard from '../VoiceCard';
import { voicesData } from '../../../data/voicesData';

const VoiceStep = ({ selectedVoice, setSelectedVoice, handleNext, handleBack }) => {
    const handleVoiceSelect = (voice) => {
        setSelectedVoice(voice);
    };

    return (
        <VStack spacing={6} align="stretch">
            <Heading size="lg">Select Voice Over</Heading>
            <Box maxHeight="60vh" overflowY="auto" pr={2}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    {voicesData.map((voice) => (
                        <VoiceCard
                            key={voice.voice_id}
                            voice={voice}
                            onSelect={() => handleVoiceSelect(voice)}
                            isSelected={selectedVoice && selectedVoice.voice_id === voice.voice_id}
                        />
                    ))}
                </SimpleGrid>
            </Box>
            {/* <Flex justifyContent="space-between" mt={4}>
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={handleNext} colorScheme="blue">Next</Button>
            </Flex> */}
        </VStack>
    );
};

export default VoiceStep;