import React, { useState } from 'react';
import { VStack, Heading, Text, SimpleGrid, Box, Image, useColorModeValue } from '@chakra-ui/react';

const BackgroundStep = ({ selectedBackground, setSelectedBackground }) => {
    const [showCustomOptions, setShowCustomOptions] = useState(false);
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const selectedBorderColor = useColorModeValue('blue.500', 'blue.300');

    const backgroundOptions = [
        { id: 'ai', name: 'AI-Generated Visuals', image: 'https://images.unsplash.com/photo-1676282824041-67dfaa132081?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
        { id: 'custom', name: 'Custom Background Video', image: 'https://plus.unsplash.com/premium_photo-1719289799297-f5491a1de014?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    ];

    const customBackgroundOptions = [
        { id: 'nature', name: 'Nature', image: 'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?auto=format&fit=crop&w=300&q=80' },
        { id: 'city', name: 'City', image: 'https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?auto=format&fit=crop&w=300&q=80' },
        { id: 'abstract', name: 'Abstract', image: 'https://images.unsplash.com/photo-1541701494587-cb58502866ab?auto=format&fit=crop&w=300&q=80' },
        { id: 'technology', name: 'Technology', image: 'https://images.unsplash.com/photo-1518770660439-4636190af475?auto=format&fit=crop&w=300&q=80' },
    ];

    const handleBackgroundChange = (bgId) => {
        if (bgId === 'custom') {
            setShowCustomOptions(true);
        } else {
            setSelectedBackground(bgId);
            setShowCustomOptions(false);
        }
    };

    const handleCustomBackgroundSelect = (bgId) => {
        setSelectedBackground(bgId);
    };

    if (showCustomOptions) {
        return (
            <VStack spacing={6} align="stretch">
                <Heading size="lg">Custom Background Videos</Heading>
                <Text>Select a custom background for your video:</Text>
                <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
                    {customBackgroundOptions.map((bg) => (
                        <Box
                            key={bg.id}
                            borderWidth="2px"
                            borderRadius="lg"
                            borderColor={selectedBackground === bg.id ? selectedBorderColor : borderColor}
                            overflow="hidden"
                            cursor="pointer"
                            onClick={() => handleCustomBackgroundSelect(bg.id)}
                            transition="all 0.3s"
                            _hover={{ transform: 'scale(1.05)' }}
                        >
                            <Image src={bg.image} alt={bg.name} objectFit="cover" height="150px" width="100%" />
                            <Box p={2}>
                                <Text fontWeight="bold" textAlign="center">{bg.name}</Text>
                            </Box>
                        </Box>
                    ))}
                </SimpleGrid>
            </VStack>
        );
    }

    return (
        <VStack backgroundColor='#121212' spacing={6} align="stretch">
            <Heading size="lg">Video Backgrounds</Heading>
            <Text>Select a video background type:</Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                {backgroundOptions.map((bg) => (
                    <Box
                        key={bg.id}
                        borderWidth="2px"
                        borderRadius="lg"
                        borderColor={selectedBackground === bg.id ? selectedBorderColor : borderColor}
                        overflow="hidden"
                        cursor="pointer"
                        onClick={() => handleBackgroundChange(bg.id)}
                        transition="all 0.3s"
                        _hover={{ transform: 'scale(1.05)' }}
                    >
                        <Image src={bg.image} alt={bg.name} objectFit="cover" height="200px" width="100%" />
                        <Box p={4}>
                            <Text fontWeight="bold" textAlign="center">{bg.name}</Text>
                        </Box>
                    </Box>
                ))}
            </SimpleGrid>
        </VStack>
    );
};

export default BackgroundStep;
