import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './dayPickerCustomStyles.css';
import { DayPicker } from 'react-day-picker';
import { format, isWithinInterval, subDays, startOfWeek, eachDayOfInterval, startOfMonth, endOfMonth, isSameMonth, isToday, addDays, addMonths, isSameDay } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  useColorModeValue,
  Flex,
  SimpleGrid,
  Skeleton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  AspectRatio,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Image,
  IconButton,
  Icon,
  HStack,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPlus, FaCalendarAlt, FaChevronDown, FaCreditCard, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiVideo, FiPlus } from 'react-icons/fi';
import { keyframes } from '@emotion/react';
import VideoCreationFlow from '../components/VideoCreationFlow';
import { useVideo } from '../context/VideoContext';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);
const MotionText = motion(Text);

const shineAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

const AnimatedButton = ({ onClick, children, icon: IconComponent, noShine = false, ...props }) => {
  return (
    <MotionBox
      as="button"
      position="relative"
      overflow="hidden"
      borderRadius="full"
      bg="purple.800"
      color="white"
      px={6}
      py={3}
      fontWeight="bold"
      _hover={{ bg: "purple.700" }}
      _active={{ bg: "purple.900" }}
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      {...props}
    >
      {!noShine && (
        <Box
          position="absolute"
          top="-50%"
          left="-50%"
          right="-50%"
          bottom="-50%"
          bg="linear-gradient(45deg, transparent, rgba(255,255,255,0.3), transparent)"
          style={{ transform: "rotate(45deg)" }}
          animation={`${shineAnimation} 3s infinite`}
        />
      )}
      <HStack spacing={2}>
        {IconComponent && <Icon as={IconComponent} />}
        <Text>{children}</Text>
      </HStack>
    </MotionBox>
  );
};

function VideoCard({ video, onClick }) {
  const cardBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <MotionBox
      bg={cardBgColor}
      borderRadius="lg"
      overflow="hidden"
      cursor="pointer"
      onClick={() => onClick(video)}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.2 }}
      boxShadow="md"
      width="100%" // Ensure full width on mobile
    >
      <AspectRatio ratio={9 / 16}>
        <Image
          src={video.thumbnailPath}
          alt={video.title}
          objectFit="cover"
        />
      </AspectRatio>
      <Box p={4}>
        <Heading size="md" noOfLines={1} color={textColor}>{video.title}</Heading>
        <Text fontSize="sm" noOfLines={2} mt={2} color={textColor}>
          {video.description}
        </Text>
      </Box>
    </MotionBox>
  );
}

function StylishModal({ isOpen, onClose, video }) {
  const modalBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent bg={modalBgColor} overflow="hidden" borderRadius="2xl">
        <ModalCloseButton size="lg" color={textColor} />
        <ModalBody p={0}>
          <Flex direction={{ base: 'column', md: 'row' }} alignItems="center">
            <Box flex={1} p={8}>
              <AspectRatio ratio={9 / 16} maxW="360px" mx="auto">
                <video
                  src={video?.videoUrl}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '16px',
                  }}
                  controls
                  autoPlay
                />
              </AspectRatio>
            </Box>
            <Box flex={1} p={8}>
              <Heading size="xl" mb={4} color={textColor}>{video?.title}</Heading>
              <Text fontSize="lg" color={textColor}>{video?.description}</Text>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const CustomDatePicker = ({ dateRange, onDateChange }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const selectedBgColor = useColorModeValue('purple.500', 'purple.200');
  const hoverBgColor = useColorModeValue('purple.100', 'purple.700');
  const todayBgColor = useColorModeValue('gray.200', 'gray.600');

  const renderMonth = (month) => {
    const daysInMonth = eachDayOfInterval({
      start: startOfMonth(month),
      end: endOfMonth(month)
    });

    const isDaySelected = (day) => {
      if (!dateRange.from) return false;
      if (!dateRange.to) return isSameDay(day, dateRange.from);
      return isWithinInterval(day, { start: dateRange.from, end: dateRange.to });
    };

    const handleDayClick = (day) => {
      if (!dateRange.from || (dateRange.from && dateRange.to)) {
        onDateChange({ from: day, to: undefined });
      } else if (day < dateRange.from) {
        onDateChange({ from: day, to: dateRange.from });
      } else {
        onDateChange({ ...dateRange, to: day });
      }
    };

    return (
      <Box>
        <Text fontWeight="bold" fontSize="lg" mb={2} textAlign="center" color={textColor}>
          {format(month, 'MMMM yyyy')}
        </Text>
        <SimpleGrid columns={7} spacing={1}>
          {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
            <Text key={day} textAlign="center" fontSize="xs" fontWeight="bold" color={textColor}>
              {day}
            </Text>
          ))}
          {daysInMonth.map(day => (
            <Button
              key={day.toString()}
              size="sm"
              variant="ghost"
              onClick={() => handleDayClick(day)}
              bg={isDaySelected(day) ? selectedBgColor : isToday(day) ? todayBgColor : 'transparent'}
              color={isDaySelected(day) ? 'white' : textColor}
              _hover={{ bg: hoverBgColor }}
              disabled={!isSameMonth(day, month)}
            >
              {format(day, 'd')}
            </Button>
          ))}
        </SimpleGrid>
      </Box>
    );
  };

  return (
    <Box bg={bgColor} p={4} borderRadius="md" boxShadow="md" width="100%"> {/* Full width on mobile */}
      <Flex justify="space-between" align="center" mb={4}>
        <IconButton
          icon={<FaChevronLeft />}
          onClick={() => setCurrentMonth(addMonths(currentMonth, -1))}
          variant="ghost"
        />
        <Text fontWeight="bold" fontSize="lg" color={textColor}>
          Select Date Range
        </Text>
        <IconButton
          icon={<FaChevronRight />}
          onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
          variant="ghost"
        />
      </Flex>
      <Flex direction={{ base: "column", md: "row" }} justify="space-between" gap={4}> {/* Stack vertically on mobile */}
        {renderMonth(currentMonth)}
        {renderMonth(addMonths(currentMonth, 1))}
      </Flex>
      <Flex direction={{ base: "column", md: "row" }} justify="space-between" mt={4}> {/* Stack vertically on mobile */}
        <Text color={textColor}>
          From: {dateRange.from ? format(dateRange.from, 'MMM dd, yyyy') : 'Not set'}
        </Text>
        <Text color={textColor}>
          To: {dateRange.to ? format(dateRange.to, 'MMM dd, yyyy') : 'Not set'}
        </Text>
      </Flex>
    </Box>
  );
};

function Videos() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showCreationFlow, setShowCreationFlow] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dateRange, setDateRange] = useState({ from: startOfWeek(new Date()), to: new Date() });
  const [selectedFilter, setSelectedFilter] = useState('This Week');
  const [startDate, setStartDate] = useState(new Date(getLastWeekDate()));
  const [endDate, setEndDate] = useState(new Date());
  const [tokenInfo, setTokenInfo] = useState(null);
  const [showCreditWarning, setShowCreditWarning] = useState(false);
  const navigate = useNavigate();

  const { fetchVideos } = useVideo();

  const bgColor = useColorModeValue('gray.50', '#121212');
  const emptyStateBgColor = useColorModeValue('white', 'gray.800');
  const alertBgColor = useColorModeValue('purple.100', 'purple.900');
  const alertTextColor = useColorModeValue('purple.800', 'purple.200');
  const buttonColorScheme = "purple";
  const textColor = useColorModeValue('gray.600', 'gray.300');

  const loadVideos = useCallback(async () => {
    setLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (!userDetails) {
      console.error('No user details found');
      setLoading(false);
      return;
    }

    const fetchStartDate = format(dateRange.from, 'yyyy-MM-dd');
    const fetchEndDate = format(dateRange.to || dateRange.from, 'yyyy-MM-dd');

    try {
      const response = await axios.get(
        `https://api.clipzy.ai/video/dateRange`,
        {
          params: {
            workspaceId: userDetails.workspaceId,
            startDate: fetchStartDate,
            endDate: fetchEndDate
          },
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }
      );
      setVideos(response.data);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setLoading(false);
    }
  }, [dateRange]);

  useEffect(() => {
    loadVideos();
    fetchTokenInfo();
  }, [loadVideos]);

  const fetchTokenInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No auth token found');
        return;
      }

      const response = await axios.get(
        'https://api.clipzy.ai/user/token-info',
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      setTokenInfo(response.data);
    } catch (error) {
      console.error('Error fetching token info:', error);
    }
  };

  const handleVideoClick = (video) => {
    navigate(`/dashboard/generated-video/${video.id}`, { state: { videoDetails: video } });
  };

  const handleCreateVideo = () => {
    navigate('/dashboard/content-studio');
  };

  const handleDateRangeChange = (newRange) => {
    if (typeof newRange === 'string') {
      setSelectedFilter(newRange);
      switch (newRange) {
        case 'Today':
          setDateRange({ from: new Date(), to: new Date() });
          break;
        case 'Yesterday':
          const yesterday = subDays(new Date(), 1);
          setDateRange({ from: yesterday, to: yesterday });
          break;
        case 'This Week':
          setDateRange({ from: startOfWeek(new Date()), to: new Date() });
          break;
        default:
          break;
      }
    } else {
      setSelectedFilter('Custom');
      setDateRange(newRange);
    }
  };

  function getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  function getLastWeekDate() {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date.toISOString().split('T')[0];
  }

  function getDateRangeStart(range) {
    const today = new Date();
    switch (range) {
      case 'Today':
        return today.toISOString().split('T')[0];
      case 'Yesterday':
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday.toISOString().split('T')[0];
      case 'This Week':
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);
        return lastWeek.toISOString().split('T')[0];
      default:
        return '';
    }
  }

  if (showCreationFlow) {
    return <VideoCreationFlow onVideoGenerated={() => {
      setShowCreationFlow(false);
      loadVideos();
    }} />;
  }

  return (
    <Box
      minHeight="100vh"
      bg={bgColor}
      py={8}
      pb={{ base: 24, md: 8 }} // Increased bottom padding for mobile
    >
      <VStack
        spacing={8}
        align="stretch"
        maxW="1200px"
        mx="auto"
        px={4}
        pb={{ base: 20, md: 0 }} // Additional padding at the bottom of the content
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "stretch", md: "center" }}
          gap={4}
        >
          <VStack align={{ base: "center", md: "start" }} spacing={2}>
            <Heading as="h1" size="xl" color={textColor}>My Videos</Heading>
            <Text fontSize="lg" color={textColor} textAlign={{ base: "center", md: "left" }}>Manage and create your video content</Text>
          </VStack>
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems="center"
            gap={4}
            width={{ base: "100%", md: "auto" }}
          >
            <Menu placement="bottom-end" autoSelect={false}>
              <MenuButton as={AnimatedButton} rightIcon={<FaChevronDown />} icon={FaCalendarAlt} noShine>
                {selectedFilter}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleDateRangeChange('Today')}>Today</MenuItem>
                <MenuItem onClick={() => handleDateRangeChange('Yesterday')}>Yesterday</MenuItem>
                <MenuItem onClick={() => handleDateRangeChange('This Week')}>This Week</MenuItem>
                <MenuItem>
                  <Popover>
                    <PopoverTrigger>
                      <Box width="100%">Custom Range</Box>
                    </PopoverTrigger>
                    <PopoverContent p={0} width={{ base: "90vw", md: "auto" }}> {/* Wider on mobile */}
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <CustomDatePicker
                          dateRange={dateRange}
                          onDateChange={(newRange) => handleDateRangeChange(newRange)}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </MenuItem>
              </MenuList>
            </Menu>
            {/* Hide the "Create New Video" button on mobile */}
            <Box display={{ base: "none", md: "block" }}>
              <AnimatedButton
                onClick={handleCreateVideo}
                icon={FiVideo}
              >
                Create New Video
              </AnimatedButton>
            </Box>
          </Flex>
        </Flex>

        <AnimatePresence>
          {showCreditWarning && (
            <MotionBox
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Alert status="warning" bg={alertBgColor} color={alertTextColor} borderRadius="md">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle mr={2} display="flex" alignItems="center">
                    <MotionText
                      initial={{ scale: 1 }}
                      animate={{ scale: [1, 1.2, 1, 1.2, 1] }}
                      transition={{ duration: 1.5, repeat: Infinity }}
                      display="inline-block"
                      mr={2}
                    >

                    </MotionText>
                    Oops! You're out of credits!
                  </AlertTitle>
                  <AlertDescription>You don't have enough credits to generate a new video.</AlertDescription>
                </Box>
                <Button
                  ml="auto"
                  colorScheme={buttonColorScheme}
                  leftIcon={<FaCreditCard />}
                  onClick={() => navigate('/dashboard/subscription-and-credits')}
                >
                  Add Credits
                </Button>
              </Alert>
            </MotionBox>
          )}
        </AnimatePresence>

        {loading ? (
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={6}> {/* Single column on mobile */}
            {[...Array(8)].map((_, index) => (
              <Skeleton key={index} height="400px" borderRadius="lg" />
            ))}
          </SimpleGrid>
        ) : videos.length > 0 ? (
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={6}> {/* Single column on mobile */}
            {videos.map(video => (
              <VideoCard
                key={video.id}
                video={video}
                onClick={() => handleVideoClick(video)}
              />
            ))}
          </SimpleGrid>
        ) : (
          <MotionBox
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            bg={emptyStateBgColor}
            p={8}
            borderRadius="xl"
            boxShadow="xl"
            textAlign="center"
          >
            <Heading as="h2" size="lg" mb={4}>
              No Videos Yet
            </Heading>
            <Text fontSize="xl" mb={6}>
              Start creating your first video to see it here!
            </Text>
            <AnimatedButton
              onClick={handleCreateVideo}
              icon={FiVideo}
              mx="auto"
            >
              Create Your First Video
            </AnimatedButton>
          </MotionBox>
        )}
      </VStack>

      {/* Floating action button for mobile */}
      <Box
        position="fixed"
        bottom="80px"
        right="24px"
        display={{ base: "block", md: "none" }}
        zIndex={10}
      >
        <AnimatedButton
          onClick={handleCreateVideo}
          size="lg"
          borderRadius="full"
          width="56px"
          height="56px"
          bg="purple.500"
          _hover={{ bg: "purple.600" }}
          _active={{ bg: "purple.700" }}
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.3)"
        >
          <Icon as={FiPlus} boxSize={6} />
        </AnimatedButton>
      </Box>

      <StylishModal isOpen={isOpen} onClose={onClose} video={selectedVideo} />
    </Box>
  );
}

export default Videos;