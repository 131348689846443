import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, useColorModeValue, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const CaptionStylePreview = ({ style, isSelected, onSelect }) => {
    const [animationProgress, setAnimationProgress] = useState(0);
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const selectedBorderColor = useColorModeValue('blue.500', 'blue.300');
    const bgColor = useColorModeValue('white', 'gray.800');

    const sampleText = "Your Amazing Caption\nText Goes Here";
    const lines = sampleText.split('\n');
    const words = lines.flatMap(line => line.split(' '));

    const highlightedWord = style.highlightLogic === 'Random' ? words[Math.floor(Math.random() * words.length)] :
        style.highlightLogic === 'First' ? words[0] :
            style.highlightLogic === 'Last' ? words[words.length - 1] :
                words[Math.floor(words.length / 2)];

    useEffect(() => {
        const animationDuration = style.renderingEffect === 'One word at a time' ? 2000 : 3000;
        const interval = setInterval(() => {
            setAnimationProgress((prev) => (prev + 0.01) % 1);
        }, animationDuration / 100);

        return () => clearInterval(interval);
    }, [style.renderingEffect]);

    return (
        <Box
            width="300px"
            height="200px"
            borderWidth="2px"
            borderColor={isSelected ? selectedBorderColor : borderColor}
            borderRadius="lg"
            overflow="hidden"
            cursor="pointer"
            onClick={onSelect}
            transition="all 0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: 'lg' }}
            position="relative"
            bg={bgColor}
        >
            <Tooltip label={`${style.name} Style`} placement="top">
                <VStack
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    bg={style.backgroundColor === 'Solid Color' ? style.backgroundGradient[0] : 'transparent'}
                    backgroundImage={style.backgroundColor === 'Gradient' ? `linear-gradient(to right, ${style.backgroundGradient[0]}, ${style.backgroundGradient[1]})` : 'none'}
                    backdropFilter={style.backgroundColor === 'Blurred' ? `blur(${style.backgroundBlur}px)` : 'none'}
                    p={4}
                >
                    {lines.map((line, lineIndex) => (
                        <Box key={lineIndex} textAlign={style.textAlignment} width="100%">
                            {line.split(' ').map((word, wordIndex) => {
                                const globalWordIndex = lineIndex * line.split(' ').length + wordIndex;
                                return (
                                    <motion.span
                                        key={globalWordIndex}
                                        style={{
                                            fontFamily: style.fontFamily,
                                            fontSize: `${style.fontSize / 2}px`,
                                            fontWeight: style.fontWeight,
                                            fontStyle: style.fontStyle,
                                            textTransform: style.textCase.toLowerCase(),
                                            color: word === highlightedWord ? style.highlightColor : style.textColor,
                                            textShadow: style.textEffect === 'Shadow' ? `2px 2px ${style.effectIntensity / 25}px rgba(0,0,0,0.5)` : 'none',
                                            WebkitTextStroke: `${style.textStrokeWidth / 2}px ${style.textStrokeColor}`,
                                            textStroke: `${style.textStrokeWidth / 2}px ${style.textStrokeColor}`,
                                            backgroundColor: word === highlightedWord && style.highlightBackgroundEnabled ? style.highlightBackgroundColor : 'transparent',
                                            padding: word === highlightedWord && style.highlightBackgroundEnabled ? '0 4px' : '0',
                                            borderRadius: word === highlightedWord && style.highlightBackgroundEnabled ? `${style.highlightCornerRadius / 2}px` : '0',
                                            display: 'inline-block',
                                            marginRight: '4px',
                                            letterSpacing: style.textWidth === 'wide' ? '0.05em' : style.textWidth === 'narrow' ? '-0.05em' : 'normal',
                                        }}
                                        initial={{ opacity: 0 }}
                                        animate={{
                                            opacity: style.renderingEffect === 'All at once' ? 1 :
                                                animationProgress > globalWordIndex / words.length ? 1 : 0
                                        }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        {word}
                                    </motion.span>
                                );
                            })}
                        </Box>
                    ))}
                </VStack>
            </Tooltip>
            {isSelected && (
                <Box
                    position="absolute"
                    top={2}
                    right={2}
                    width={4}
                    height={4}
                    borderRadius="full"
                    bg={selectedBorderColor}
                />
            )}
        </Box>
    );
};

export default CaptionStylePreview;