import React, { useState, useCallback } from 'react';
import { Player } from '@remotion/player';
import {
  Box, Flex, VStack, HStack, Text, Heading, Input, Button,
  IconButton, Card, CardBody, Select, AspectRatio,
  Tooltip, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Editable, EditableInput, EditablePreview,
  useMediaQuery, SimpleGrid, Container, useColorModeValue
} from '@chakra-ui/react';
import { FiUpload, FiPlay, FiPause, FiMusic, FiVideo, FiImage, FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { FormLabel } from '@chakra-ui/react';
import outputVideo from '../../src/output.mp4';

import Sidebar from '../components/Sidebar';
import BottomTabBar from '../components/BottomTabBar';

function SplitScreen() {
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState("Untitled Project");
  const [topMediaType, setTopMediaType] = useState('video');
  const [bottomMediaType, setBottomMediaType] = useState('video');
  const [topMedia, setTopMedia] = useState(null);
  const [bottomMedia, setBottomMedia] = useState(null);
  const [previewPlaying, setPreviewPlaying] = useState(false);
  const [backgroundVideo, setBackgroundVideo] = useState(outputVideo);

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const bgColor = useColorModeValue('gray.50', '#121212');
  const cardBgColor = useColorModeValue('white', '#1E1E1E');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.300');
  const accentColor = useColorModeValue('blue.500', 'blue.300');

  const handleFileUpload = useCallback((event, position) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      if (position === 'top') {
        setTopMedia(url);
      } else {
        setBottomMedia(url);
      }
    }
  }, []);

  const MediaUpload = ({ type, position }) => (
    <Button
      as="label"
      leftIcon={type === 'video' ? <FiVideo /> : <FiImage />}
      w="full"
      variant="outline"
      colorScheme="purple"
      cursor="pointer"
      size="sm"
    >
      Upload {type}
      <Input
        type="file"
        accept={type === 'video' ? 'video/*' : 'image/*'}
        onChange={(e) => handleFileUpload(e, position)}
        hidden
      />
    </Button>
  );

  return (
    <Flex flexDirection="column" height="100vh">
      <Flex flex={1} overflow="hidden">
        {!isMobile && <Sidebar />}
        <Box flex={1} display="flex" flexDirection="column" bg={bgColor} overflowY="auto">
          <Container maxW="container.xl" py={4}>
            <VStack spacing={4} align="stretch">
              <HStack spacing={4} bg={cardBgColor} p={4} borderRadius="md">
                <IconButton icon={<FiArrowLeft />} onClick={() => navigate('/dashboard')} aria-label="Back to Dashboard" variant="ghost" color={textColor} />
                <Editable value={projectName} onChange={setProjectName}>
                  <EditablePreview fontWeight="bold" color={textColor} />
                  <EditableInput color={textColor} />
                </Editable>
              </HStack>
              
              <Heading size="md" textAlign="center" color={textColor}>Split Screen Video Generator</Heading>

              <Flex>
                <VStack spacing={4} align="stretch" width="40%" mr={4}>
                  <Card bg={cardBgColor}>
                    <CardBody>
                      <VStack spacing={4}>
                        <Text fontSize="sm" fontWeight="bold" color={textColor}>Top Screen</Text>
                        <Select value={topMediaType} onChange={(e) => setTopMediaType(e.target.value)} color={textColor} size="sm">
                          <option value="video">Video</option>
                          <option value="image">Image</option>
                        </Select>
                        <MediaUpload type={topMediaType} position="top" />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card bg={cardBgColor}>
                    <CardBody>
                      <VStack spacing={4}>
                        <Text fontSize="sm" fontWeight="bold" color={textColor}>Bottom Screen</Text>
                        <Select value={bottomMediaType} onChange={(e) => setBottomMediaType(e.target.value)} color={textColor} size="sm">
                          <option value="video">Video</option>
                          <option value="image">Image</option>
                        </Select>
                        <MediaUpload type={bottomMediaType} position="bottom" />
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card bg={cardBgColor}>
                    <CardBody>
                      <VStack spacing={4} align="stretch">
                        <Text fontSize="sm" fontWeight="bold" color={textColor}>Video Settings</Text>
                        <Box>
                          <Text fontSize="xs" mb={1} color={secondaryTextColor}>Duration (seconds)</Text>
                          <NumberInput defaultValue={10} min={5} max={60} color={textColor} size="sm">
                            <NumberInputField borderColor="purple.500" />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                        </Box>
                        <Box>
                          <Text fontSize="xs" mb={1} color={secondaryTextColor}>Transition Effect</Text>
                          <Select size="sm" borderColor="purple.500" color={textColor}>
                            <option value="none">None</option>
                            <option value="fade">Fade</option>
                            <option value="slide">Slide</option>
                            <option value="zoom">Zoom</option>
                          </Select>
                        </Box>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Card bg={cardBgColor}>
                    <CardBody>
                      <VStack spacing={4} align="stretch">
                        <FormLabel fontSize="sm" fontWeight="bold" color={textColor}>Select Audio</FormLabel>
                        <Button
                          size="sm"
                          leftIcon={<FiUpload />}
                          onClick={() => {}} // Add your audio selection logic here
                          colorScheme="purple"
                        >
                          Select Audio File
                        </Button>
                      </VStack>
                    </CardBody>
                  </Card>
                </VStack>

                <Box flex={1} bg="gray.900" borderRadius="lg" overflow="hidden" boxShadow="lg">
                  <AspectRatio ratio={9/16} maxW="300px" mx="auto">
                    <video src={backgroundVideo} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </AspectRatio>
                </Box>
              </Flex>
            </VStack>
          </Container>

          <Box position="sticky" bottom={0} p={4} bg={bgColor} borderTop="1px solid" borderColor="gray.700">
            <Button
              colorScheme="purple"
              size="lg"
              w="full"
            >
              Generate Video
            </Button>
          </Box>
        </Box>
      </Flex>
      {isMobile && <BottomTabBar />}
    </Flex>
  );
}

export default SplitScreen;