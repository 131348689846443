import React from 'react';
import { VStack, Heading, Text, SimpleGrid } from '@chakra-ui/react';
import StyleCard from '../StyleCard';

const VisualStyleStep = ({ formData, setFormData }) => {
    const styles = ['Colorful Comics', 'Clipart', 'Cinematic', 'Pixel Art', 'Anime', 'CyberPunk'];

    const handleStyleChange = (style) => {
        setFormData(prev => ({ ...prev, imageStyle: style }));
    };

    return (
        <VStack spacing={6} align="stretch">
            <Heading size="lg">Visual Style</Heading>
            <Text>Choose a visual style for your video</Text>
            <SimpleGrid columns={{ base: 2, sm: 3, md: 4, lg: 6 }} spacing={4}>
                {styles.map((style) => (
                    <StyleCard
                        key={style}
                        style={style}
                        isSelected={formData.imageStyle === style}
                        onClick={() => handleStyleChange(style)}
                    />
                ))}
            </SimpleGrid>
        </VStack>
    );
};

export default VisualStyleStep;