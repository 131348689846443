import React, { useState, useEffect, useMemo } from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const captionStyle = {
  fontFamily: "MONTSERRAT, sans-serif",
  fontSize: 24,
  fontWeight: "normal",
  fontStyle: "normal",
  textCase: "UPPER CASE",  // Changed to uppercase
  textColor: "#FFFFFF",
  highlightColor: "#15d8ed",  // Changed highlight color to blue
  highlightStrokeColor: "#000000",
  highlightBackgroundColor:  "None",
  textAlignment: "center",
  textEffect: "None",
  backgroundColor: "transparent",
  backgroundGradient: ["#000000", "#FFFFFF"],
  backgroundBlur: 0,
  emphasisTechnique: "Bold",
  wordsPerLine: 5,
  lineHeight: 1.5,
  effectIntensity: 50,
  textStrokeWidth: 0,
  textStrokeColor: "#000000",
  highlightStrokeWidth: 0,
  highlightCornerRadius: 4,
  renderingEffect: "All at once"
};

// Function to split text into lines based on the logic in process_scene.py
function splitTextIntoLines(data, MaxChars=40, MaxDuration=2.5, MaxGap=1.5, MaxWords=5) {
  const subtitles = [];
  let line = [];
  let lineDuration = 0;
  let lineChars = 0;
  let lineWords = 0;

  for (let idx = 0; idx < data.length; idx++) {
    const wordData = data[idx];
    const word = wordData.word;
    const start = wordData.start;
    const end = wordData.end;

    line.push(wordData);
    lineDuration += end - start;
    lineChars += word.length;
    lineWords += 1;

    const gap = idx > 0 ? start - data[idx - 1].end : 0;

    const shouldSplit = (
      lineDuration > MaxDuration ||
      lineChars > MaxChars ||
      gap > MaxGap ||
      lineWords >= MaxWords ||
      /[.,?!]/.test(word.slice(-1))
    );

    if (shouldSplit && line.length > 0) {
      subtitles.push({
        word: line.map(item => item.word).join(' '),
        start: line[0].start,
        end: line[line.length - 1].end,
        textcontents: line
      });
      line = [];
      lineDuration = 0;
      lineChars = 0;
      lineWords = 0;
    }
  }

  if (line.length > 0) {
    subtitles.push({
      word: line.map(item => item.word).join(' '),
      start: line[0].start,
      end: line[line.length - 1].end,
      textcontents: line
    });
  }

  return subtitles;
}

function RemotionPlayer({ currentClip, currentTime, wordTimings }) {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const wrappedLines = useMemo(() => {
    if (!wordTimings) return [];
    return splitTextIntoLines(wordTimings);
  }, [wordTimings]);

  useEffect(() => {
    if (!wordTimings || wordTimings.length === 0) return;

    const currentWordIndex = wordTimings.findIndex(
      (word) => currentTime >= word.start && currentTime < word.end
    );

    setCurrentWordIndex(currentWordIndex === -1 ? wordTimings.length - 1 : currentWordIndex);
  }, [currentTime, wordTimings]);

  return (
    <Box
      width="100%"
      maxWidth="414px"
      height="100%"
      maxHeight="896px"
      margin="0 auto"
      position="relative"
      overflow="hidden"
      borderRadius="md"
      boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
      bg={captionStyle.backgroundColor}
      backgroundImage={`linear-gradient(to bottom, ${captionStyle.backgroundGradient.join(', ')})`}
    >
      {currentClip && (
        <>
          <Image
            src={currentClip.src}
            alt="Current clip"
            objectFit="cover"
            width="100%"
            height="100%"
            filter={`blur(${captionStyle.backgroundBlur}px)`}
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            width="90%"
            textAlign={captionStyle.textAlignment}
          >
            {wrappedLines.map((line, lineIndex) => (
              <motion.div
                key={lineIndex}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Text
                  fontFamily={captionStyle.fontFamily}
                  fontSize={`${captionStyle.fontSize}px`}
                  fontWeight={captionStyle.emphasisTechnique === "Bold" ? "bold" : captionStyle.fontWeight}
                  fontStyle={captionStyle.fontStyle}
                  color={captionStyle.textColor}
                  textTransform={captionStyle.textCase === "UPPER CASE" ? "uppercase" : "none"}
                  mb={2}
                  textShadow={`${captionStyle.textStrokeWidth}px ${captionStyle.textStrokeWidth}px 0 ${captionStyle.textStrokeColor}`}
                  sx={{
                    background: captionStyle.highlightBackgroundColor,
                    padding: '4px 8px',
                    borderRadius: `${captionStyle.highlightCornerRadius}px`,
                    boxDecorationBreak: 'clone',
                    WebkitBoxDecorationBreak: 'clone',
                    lineHeight: captionStyle.lineHeight,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {line.textcontents.map((word, wordIndex) => {
                    const globalWordIndex = wordTimings.findIndex(w => w.word === word.word && w.start === word.start);
                    const isCurrentWord = globalWordIndex === currentWordIndex;
                    return (
                      <span
                        key={wordIndex}
                        style={{
                          color: isCurrentWord ? captionStyle.highlightColor : captionStyle.textColor,
                          textShadow: isCurrentWord ? `${captionStyle.highlightStrokeWidth}px ${captionStyle.highlightStrokeWidth}px 0 ${captionStyle.highlightStrokeColor}` : undefined,
                        }}
                      >
                        {word.word}{' '}
                      </span>
                    );
                  })}
                </Text>
              </motion.div>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
}

export default RemotionPlayer;
