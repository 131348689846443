import React from 'react';
import { VStack, Heading, Text, SimpleGrid, Box, Button } from '@chakra-ui/react';
import CaptionStylePreview from '../CaptionStylePreview';
import { captionTemplates } from '../../../constants/videoCreationConstants';

const CaptionStep = ({ selectedCaptionTemplate, setSelectedCaptionTemplate }) => {
    const handleCaptionTemplateChange = (templateName) => {
        setSelectedCaptionTemplate(templateName);
    };

    return (
        <VStack spacing={6} align="stretch">
            <Heading size="lg">Caption Settings</Heading>
            <Text>Select a caption style for your video</Text>
            <SimpleGrid columns={[1, 2, 3]} spacing={6}>
                {captionTemplates.map((template) => (
                    <CaptionStylePreview
                        key={template.name}
                        style={template.style}
                        isSelected={selectedCaptionTemplate === template.name}
                        onSelect={() => handleCaptionTemplateChange(template.name)}
                    />
                ))}
            </SimpleGrid>
            {selectedCaptionTemplate && (
                <Box mt={4}>
                    <Text fontWeight="bold">Selected Style: {selectedCaptionTemplate}</Text>
                    <Button mt={2} colorScheme="blue" onClick={() => handleCaptionTemplateChange('')}>
                        Clear Selection
                    </Button>
                </Box>
            )}
        </VStack>
    );
};

export default CaptionStep;